const Theme = {
    fonts: {
        fontFace: {
          family: 'Roboto',
          file: '/fonts/Roboto-Regular.ttf',
        },
        body: `Roboto, sans-serif`,
      },
    colors: {
      primary: '#FF8800',
      background: '#110F1C',
      shape: `#090811`,
      title: `#F2F2FA`,
      text: `#AFC5EE`,
      components: {
        blockquote: {
          background: `#FF8800`,
          text: `#110F1C`,
        },
      },
    },
  };

export default Theme;